<template>
    <div>
        <b-button :to="{ name:'create-redirect'}" class="mb-1 mr-1" variant="relief-primary">
            {{ $t('new') }}
        </b-button>
        <b-button class="mb-1" variant="info" @click="visible = !visible">
            {{ $t('search') }}
        </b-button>
        <custom-filter :filters="filters" :visible="visible" @onSubmit="filters => filter(filters)">
            <template #form="{filters}">
                <b-row>
                    <b-col md="2">
                        <custom-select v-model="filters.type" :options="redirectTypeOptions" :name="$t('type')" vid="type"/>
                    </b-col>
                </b-row>
            </template>
        </custom-filter>
        <b-card no-body>
            <b-table :busy="status === 'LOADING'" :fields="fields" :items="Object.values(redirects)" hover responsive>
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"/>
                    </div>
                </template>
                <template #cell(type)="data">
                    <b-badge :variant="typeColor(data.item.type)">
                        {{ typeText(data.item.type) }}
                    </b-badge>
                </template>

                <template #cell(action)="data">
                    <b-dropdown no-caret variant="link">
                        <template #button-content>
                            <feather-icon icon="MoreHorizontalIcon"/>
                        </template>
                        <b-dropdown-item :to="{name: 'edit-redirect', params:{id: data.item.id}}">
                            <feather-icon icon="Edit2Icon"/>
                            {{ $t('edit') }}
                        </b-dropdown-item>
                        <b-dropdown-item @click="confirmDelete(data.item.id)">
                            <feather-icon icon="XIcon"/>
                            {{ $t('delete') }}
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>
            <b-pagination
                v-if="pagination.last_page > 1"
                v-model="pagination.current_page"
                :per-page="pagination.per_page"
                :total-rows="pagination.total"
                align="center"
                class="mt-2"
                @change="paginationCategory"
            />
        </b-card>
    </div>
</template>

<script>
import fields from "./partials/fields";
import { mapActions, mapGetters } from "vuex";
import { redirectTypeOptions } from "@/common/data";
import RedirectType from "@/constans/RedirectType";

export default {
    name: "Redirects",

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchRedirects()
        })
    },

    data() {
        return {
            fields,
            visible: false,
            redirectTypeOptions,
            filters: {
                type: '',
            }
        }
    },

    computed: {
        ...mapGetters('redirect', [
            'redirects', 'status', 'pagination'
        ]),
        ...mapGetters('filter', [
            'filterFields'
        ])
    },

    methods: {
        ...mapActions({
            fetchRedirects: 'redirect/fetch',
            deleteRedirect: 'redirect/delete'
        }),
        filter(filters) {
            this.fetchRedirects({
                ...filters,
                page: 1
            })
        },
        paginationCategory(page) {
            this.fetchRedirects({
                page,
                ...this.filterFields
            })
        },
        confirmDelete(id) {
            this.$swal({
                title: this.$t('Are you sure?'),
                text: this.$t("You won't be able to revert this!"),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('Yes, delete it!'),
                cancelButtonText: this.$t('No'),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    this.deleteRedirect(id).then(() => {
                        this.$swal({
                            icon: 'success',
                            title: this.$t(`Deleted!`),
                            text: this.$t('Your file has been deleted.'),
                            confirmButtonText: this.$t('Ok!'),
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                    })
                }
            })
        },
        typeColor(value) {
            if (value === RedirectType.CANONICAL) return 'warning'
            if (value === RedirectType.REDIRECT_301) return 'success'
            if (value === RedirectType.REDIRECT_302) return 'info'

        },
        typeText(value) {
            if (value === RedirectType.CANONICAL) return this.$t('canonical')
            if (value === RedirectType.REDIRECT_302) return this.$t('redirect_302')
            if (value === RedirectType.REDIRECT_301) return this.$t('redirect_301')
        }
    }
}
</script>
