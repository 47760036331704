import i18n from "@/libs/i18n";

const fields = [
    {
        key: 'source',
        label: i18n.t('source'),
    },
    {
        key: 'destination',
        label: i18n.t('destination'),
    },
    {
        key: 'type',
        label: i18n.t('type'),
    },
    {
        key: 'created_at',
        label: i18n.t('created_at'),
    },
    {
        key: 'action',
        label: i18n.t('action'),
    },
]

export default fields